

























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import logCollection, { Log } from '@/models/logs'
import { User, converter as userConverter } from '@/models/users'
import DisplayTime from '@/components/common/display-time.vue'
import HelpLog from '@/components/help/log.vue'
import AuthStore from '@/store/auth'

@Component<LogList>({
  components: { DisplayTime, HelpLog },
  created () {
    this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
    if (this.unsubscribeCount) this.unsubscribeCount()
  }
})
export default class LogList extends Vue {
  headers = [
    { value: 'createdAt', text: '생성일' },
    { value: 'type', text: '유형' },
    { value: 'title', text: '제목' },
    { value: 'body', text: '내용' },
    { value: 'user', text: '사용자' },
    { value: 'notice', text: '알림' },
    { value: 'link', text: '링크' }
  ]

  menu = false
  dates = [
    this.$moment().format('YYYY-MM-DD'),
    this.$moment().format('YYYY-MM-DD')
  ]

  limit = 10

  users: User[] = []

  empty = false

  dialog = false

  unsubscribe: Function | null = null
  unsubscribeCount: Function | null = null
  snapshots: firebase.firestore.QueryDocumentSnapshot<Log>[] = []
  userSnapshots: firebase.firestore.DocumentReference<User>[] = []

  @Watch('dates')
  onChangeDates () {
    if (this.dates.length < 2) return
    this.snapshots = []
    this.subscribe()
  }

  get xs () { return this.$vuetify.breakpoint.xs }

  get firstSnapshot () {
    return this.snapshots[0]
  }

  get lastSnapshot () {
    return this.snapshots[this.snapshots.length - 1]
  }

  get items () {
    const items = this.snapshots.map(doc => doc.data())
    items.forEach(item => {
      item.decodeUser = this.users.find(u => u.id === item.user?.id)
    })
    // console.log(items)
    return items // this.snapshots.map(doc => doc.data())
  }

  get dateRangeText () {
    return this.dates.join(' ~ ')
  }

  get firstTime () {
    if (this.dates.length < 1) return null
    return this.$moment(this.dates[0]).utcOffset(9).toDate()
  }

  get lastTime () {
    if (this.dates.length < 2) return null
    return this.$moment(this.dates[1]).utcOffset(9).add(1, 'days').toDate()
  }

  get isDev () { return AuthStore.isDev }

  async getUser (snapshot: firebase.firestore.QueryDocumentSnapshot<Log>) {
    const doc = snapshot.data()
    if (!doc.user) return
    if (!this.users.some(u => u.id === doc.user?.id)) {
      const userDoc = await doc.user.withConverter(userConverter).get()
      const user = userDoc.data()
      if (!user) return
      this.users.push(user)
    }
  }

  async shiftSnapshots (snapshots: firebase.firestore.QueryDocumentSnapshot<Log>[]) {
    const newSnapshots = snapshots.filter(doc => {
      return !this.snapshots.some(oldDoc => oldDoc.id === doc.id)
    })

    while (newSnapshots.length > 0) {
      const snapshot = newSnapshots.pop()
      if (!snapshot) return
      this.snapshots.unshift(snapshot)
      await this.getUser(snapshot)
    }
  }

  async pushSnapshots (snapshots: firebase.firestore.QueryDocumentSnapshot<Log>[]) {
    const newSnapshots = snapshots.filter(doc => {
      return !this.snapshots.some(oldDoc => oldDoc.id === doc.id)
    })

    while (newSnapshots.length > 0) {
      const snapshot = newSnapshots.shift()
      if (!snapshot) return
      this.snapshots.push(snapshot)
      await this.getUser(snapshot)
    }
  }

  subscribe () {
    this.unsubscribe = logCollection
      .where('createdAt', '>=', this.firstTime)
      .where('createdAt', '<', this.lastTime)
      .orderBy('createdAt', 'desc').limit(1).onSnapshot(sn => {
        if (sn.empty) return
        // this.snapshots = sn.docs
        this.shiftSnapshots(sn.docs)
      }, (e) => {
        console.error(e.message)
      })
    setTimeout(() => this.more(), 300)
  }

  async more () {
    if (!this.lastSnapshot) return
    const sn = await logCollection
      .where('createdAt', '>=', this.firstTime)
      .where('createdAt', '<', this.lastTime)
      .orderBy('createdAt', 'desc').startAfter(this.lastSnapshot).limit(this.limit).get()
    this.empty = sn.empty
    if (sn.empty) return
    this.pushSnapshots(sn.docs)
  }

  onIntersect (entries: unknown, observer: unknown, isIntersecting: unknown) {
    if (isIntersecting) this.more()
  }

  clear () {
    if (!this.isDev) throw Error('개발자만 가능한 기능입니다')
    while (this.snapshots.length) {
      const snapshot = this.snapshots.pop()
      if (!snapshot) return
      snapshot.ref.delete()
    }
  }

  getTitle (item: Log) {
    const title = item.title
    const type = Number(title)
    if (isNaN(type)) return title

    const p = title.padStart(11, '0')
    const ps = [
      p.substr(0, 3),
      p.substr(3, 4),
      p.substr(7, 4)
    ]
    if (process.env.VUE_APP_TEST) ps[1] = 'xxxx'
    return ps.join('-')
  }
}
