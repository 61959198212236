



import { Vue, Component } from 'vue-property-decorator'
import LogList from '@/components/log/list.vue'

@Component({ components: { LogList } })
export default class ViewLog extends Vue {
}
